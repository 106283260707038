import { useEffect, useState } from "react";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const WarningPopup = () => {

  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showNoticePopup, setShowNoticePopup] = useState(false);
  const [countDown, setCountdown] = useState(process.env.REACT_APP_REDIRECT_TIMEOUT)
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    const warningShown = localStorage.getItem('popupShown');
    console.log(warningShown);
    if (!warningShown) {
      setShowWarningPopup(true);
      localStorage.setItem('popupShown', true);
    } else {
      setShowNoticePopup(true);
      if(showNoticePopup) {
        // Start countdown when warning was previously shown
        const countdown = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(countdown);
              goToPijar();
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        setTimer(countdown);
  
        // Cleanup using countdown from closure
        return () => clearInterval(countdown);
      }
    }
  }, [showNoticePopup]); 

  const closeWarningPopup = () => {
    setShowWarningPopup(false);
    setShowNoticePopup(true);
  };

  const closeNoticePopup = () => {
    setShowNoticePopup(false);
  }

  const goToPijar = () => {
    window.location.href = process.env.REACT_APP_PIJAR_URL;
  }
  return (
    <>
      {showWarningPopup && (
        <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative mt-15 w-3/5 bg-white p-6 rounded-lg shadow-lg text-center">
            <button
              onClick={closeWarningPopup}
              className="absolute top-2 right-5 text-gray-500 hover:text-gray-700 text-xl"
            >
              &times;
            </button>
            <img className="mx-auto" src={require('../images/logo-3 1.png')} alt="logo-iki" /><br/>
            <h2 className="text-2xl font-bold mb-4 text-red-600">PERINGATAN :</h2><br/>
            <p className="mb-10 text-center">
            HATI-HATI, TRANSAKSI INI  BERISIKO TINGGI. ANDA DAPAT SAJA MENGALAMI KERUGIAN ATAU KEHILANGAN  UANG. JANGAN BERUTANG JIKA TIDAK MEMILIKI KEMAMPUAN MEMBAYAR.  PERTIMBANGKAN SECARA BIJAK SEBELUM BERTRANSAKSI.
            </p>
            <p className="mb-4 text-sm text-center">
              IKI Modal adalah platform P2P Lending yang telah berizin dan diawasi oleh Otoritas Jasa Keuangan. Kami menghimbau untuk WASPADA terhadap segala bentuk informasi yang mengatasnamakan PT IKI Karunia Indonesia (IKI Modal).<br /><br />
              Cek kebenaran informasi yang kamu terima melalui kontak resmi kami:<br/>
              Call Center (Whatsapp) : 081211117359<br/>
              Email : helpdesk@iki.id<br/>
              marketing@iki.id 
            </p>
          </div>
        </div>
      )},
      { showNoticePopup && (
          <div className="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-50">
              <div className="relative mt-15 lg:w-2/6 sm:w-100 md:w-4/5 bg-white p-6 rounded-lg shadow-lg text-center">
                  <h1 className="text-4xl font-bold mb-4 text-black-600">PENGUMUMAN</h1><hr/>
                  <div className="flex flex-row justify-center py-10">
                      <div className="flex justify-center items-center">
                          <img className="h-10 w-full object-contain" src={require("../images/logo-3 1.png")} alt="logo-iki" />
                      </div>
                      <div className="flex justify-center items-center">
                          <FontAwesomeIcon icon={faAngleRight} className="mx-10 text-4xl text-black-600" />
                      </div>
                      <div className="flex justify-center items-center">
                          <img className="h-14 w-full object-contain" src={require("../images/pijar.png")} alt="logo-pijar" />
                      </div>
                  </div>
                  <h4 className="mb-4 text-xl text-center">
                    Sebentar lagi IKI modal secara permanen hanya dapat diakses melalui <a className="text-red-600" href={process.env.REACT_APP_PIJAR_URL}>pijar.com</a>
                    <br />
                    <span className="text-red-600">Redirecting in {countDown} seconds...</span>
                  </h4>
                  <button className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700" onClick={goToPijar}>Kunjungi Pijar</button>
              </div>
          </div>
      )}
    </>
  );
}

export default WarningPopup